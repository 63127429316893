import WOW from "wowjs";
import Intercom from '@intercom/messenger-js-sdk';

Intercom({
  app_id: 'l8bhi56i',
});

window.wow = new WOW.WOW({
  live: false,
});

window.wow.init({
  offset: 50,
});

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

export function scrollTo(element, to = 0, duration = 500) {
  const start = element.scrollTop;
  const change = to - start;
  const increment = 20;
  let currentTime = 0;

  const animateScroll = () => {
    currentTime += increment;

    const val = Math.easeInOutQuad(currentTime, start, change, duration);

    element.scrollTop = val;

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };

  animateScroll();
}

export function sendWhatsappMessage(
  message = "Hola, tengo preguntas sobre la aplicación MiDespacho."
) {
  const phoneNumber = "525554560150";
  const urlWhatsApp = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  // Abre la URL en una nueva ventana o pestaña
  window.open(urlWhatsApp, "_blank");
}
